import React from 'react'

import './layout.scss'
import { rhythm, scale } from '../utils/typography'

const Layout = ({ children }) => (
    <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}>
        {children}
      </div>
)

export default Layout