import Typography from 'typography'
import funstonTheme from 'typography-theme-funston'
//import irvingTheme from 'typography-theme-irving'

const typography = new Typography(
    funstonTheme
)

export default typography

export const rhythm = typography.rhythm
export const scale = typography.scale