import React from 'react'

import Layout from '../components/layout'

const AboutPage = () => (
    <Layout>
    <div>
      <h1>Welcome to About page!</h1>
      <p>This is about me</p>
    </div>
    </Layout>
)

export default AboutPage
